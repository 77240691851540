<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">코스 그룹 {{ typeform.id !== '' ? '수정' : '등록' }}</h4>
          <p class="card-title-desc">
            코스 그룹을 {{ typeform.id !== '' ? '수정' : '등록' }}합니다.
          </p>
          <form action="#" @submit.prevent="typeForm">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>그룹명</label>
                  <input
                    v-model="typeform.title"
                    type="text"
                    class="form-control"
                    placeholder="코스 그룹 이름을 입력하세요."
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.title.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.title.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.title.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>정렬순서</label>
                  <input
                    v-model="typeform.orderNum"
                    type="text"
                    class="form-control"
                    placeholder="그룹 정렬 순서(높을수록 상위노출)"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.orderNum.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.orderNum.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.orderNum.required">값을 입력해주세요.</span>
                    <span v-if="!$v.typeform.orderNum.numeric">숫자만 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>공개여부</label>
                  <select v-model="typeform.openYn" class="form-control">
                    <option value="Y" selected>공개</option>
                    <option value="N">비공개</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary me-1">
                    {{ typeform.id !== '' ? '수정' : '등록' }}
                  </button>
                  <button
                    type="reset"
                    class="btn btn-secondary m-l-5 ml-1"
                    @click="$router.push('/group')"
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, numeric} from 'vuelidate/lib/validators';
export default {
  components: {},
  mixins: [validationMixin],
  props: {
    pageData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      typeform: {
        id: '',
        title: '', //그룹명
        orderNum: '', //그룹 정렬
        openYn: 'Y'
      },
      typesubmit: false
    };
  },
  mounted() {
    console.log(this.pageData);
    if (Object.keys(this.pageData).length > 0) {
      console.log(this.pageData);
      this.typeform.id = this.pageData.idx;
      this.typeform.title = this.pageData.name;
      this.typeform.orderNum = this.pageData.sort;
      this.typeform.openYn = this.pageData.openYn;
    }
  },
  methods: {
    typeForm() {
      this.typesubmit = true;
      this.$v.typeform.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch('course/courseGroupAdd', {
            id: this.typeform.id,
            title: this.typeform.title,
            orderNum: this.typeform.orderNum,
            openYn: this.typeform.openYn
          })
          .then(res => {
            if (res.result) {
              alert(res.message);
              this.$router.push('/group');
            } else {
              alert('실패하였습니다.');
            }
          });
      }
    }
  },
  validations: {
    typeform: {
      title: {required},
      orderNum: {required, numeric}
    }
  }
};
</script>

<style lang="scss" scoped></style>
